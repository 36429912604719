'use strict';

Gri.module({
  name: 'select-beta',
  ieVersion: null,
  $el: $('.select-beta'),
  container: '.select-beta',
  fn: function () {

    // region variables
    const $this = this.$el;
    // endregion

    // region run
    $this.find('select').selectpicker();
    // endregion

    // region events

    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion

  }
});
